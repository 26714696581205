import React, { useMemo, Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Loader from "Components/Loader/Loader";

const UsersContainer = React.lazy(() =>
  import("Container/Users/UsersContainer/UsersContainer")
);
const UsersDetailContainer = React.lazy(() =>
  import("Container/Users/UsersDetailContainer/UsersDetailContainer")
);
const MenuContainer = React.lazy(() =>
  import("Container/Menu/MenuContainer/MenuContainer")
);
const MenuDetailContainer = React.lazy(() =>
  import("Container/Menu/MenuDetailContainer/MenuDetailContainer")
);
const MenuCreateContainer = React.lazy(() =>
  import("Container/Menu/MenuCreateContainer/MenuCreateContainer")
);
import { categories } from "Components/Category";
import { capacityNames } from "Components/CapacityNames";
import { capacityProducts } from "Components/CapacityProducts";
import { products } from "Components/Products";
import tags from "./tags/index";
import comments from "./comments";
import xml from "./xml";
import blogStyle from "./blogStyle";
import blogCategory from "./blogCategory";
import blog from "./blog";
import images from "./images";
import ingredients from "./ingredients";
import recipes from "./recipes";
import promoCodes from "./promoCode";
import newBanners from "./banners";
import awards from "./awards";
import ratings from "./ratings";
import orders from "./orders";
import mobileApp from "./mobileApp";
import cities from "./locations/cities";
import districts from "./locations/districts";
import markets from "./locations/markets";
import characteristicKey from "./locations/characteristicKey";
import characteristicValue from "./locations/characteristicValue";
import producer from "./producer";
import { schedule } from "../Components/Schedule";
import { upperBanners } from "../Components/UpperBanners";
import selections from "./selections";
import selectionAssociations from "./selectionAssociations";
import search from "./search";
import { characteristicSeo } from "Components/CharacteristicSeo";
import { contactMethods } from "../Components/ContactMethods";
import { cartSettings } from "../Components/CartSettings";

const MockComponent = ({ text }) => <h2>{text || "JUST TEXT"}</h2>;

const routesData = [
  {
    path: "/users",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <UsersContainer />
      </Suspense>
    )
  },
  {
    path: "/users/:id",
    component: (
      <Suspense fallback={<Loader />}>
        <UsersDetailContainer />
      </Suspense>
    )
  },
  {
    path: "/prod-menu",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <MenuContainer />
      </Suspense>
    )
  },
  {
    path: "/prod-menu/create/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <MenuCreateContainer />
      </Suspense>
    )
  },
  {
    path: "/prod-menu/:id",
    component: (
      <Suspense fallback={<Loader />}>
        <MenuDetailContainer />
      </Suspense>
    )
  },
  ...upperBanners,
  ...categories,
  ...capacityNames,
  ...capacityProducts,
  ...products,
  ...tags,
  ...comments,
  ...xml,
  ...schedule,
  ...blogStyle,
  ...blogCategory,
  ...blog,
  ...images,
  ...ingredients,
  ...recipes,
  ...promoCodes,
  ...newBanners,
  ...awards,
  ...ratings,
  ...orders,
  ...mobileApp,
  ...cities,
  ...districts,
  ...markets,
  ...characteristicKey,
  ...characteristicValue,
  ...producer,
  ...selections,
  ...selectionAssociations,
  ...search,
  ...characteristicSeo,
  ...contactMethods,
  ...cartSettings
];

const Pages = ({ menu }) => {
  const newPages = useMemo(() =>
    routesData.filter(item => {
      const pureURL = item.path.replaceAll(":id", "").replaceAll("/", "");
      const has = menu.some(selectd =>
        selectd.children.some(item2 => item2.url.includes(pureURL))
      );
      return { ...has, exact: item.exact };
    })
  );

  return (
    <Switch>
      <Route path="/" exact render={() => <MockComponent text="JUST PAGE" />} />
      {newPages.map(item => (
        <Route
          exact={!!item.exact}
          key={item.path}
          path={item.path}
          render={() => item.component}
        />
      ))}
      <Route path="/404" render={() => <MockComponent text="404" />} />
      <Redirect to="/404" />
    </Switch>
  );
};

export default Pages;
