import React, { Suspense } from "react";
import Loader from "../../Components/Loader/Loader";

const SelectionList = React.lazy(() =>
  import("Components/Selections/SelectionList/SelectionList")
);
const SelectionUpdate = React.lazy(() =>
  import("Components/Selections/SelectionUpdate/SelectionUpdate")
);
const SelectionCreate = React.lazy(() =>
  import("Components/Selections/SelectionCreate/SelectionCreate")
);

const urls = [
  {
    path: "/selections",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <SelectionList />
      </Suspense>
    )
  },
  {
    path: "/selection/create/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <SelectionCreate />
      </Suspense>
    )
  },
  {
    path: "/selection/:id",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <SelectionUpdate />
      </Suspense>
    )
  }
];

export default urls;
