import React, { Suspense } from "react";
import { ROUTES } from "app/routes";
import Loader from "Components/Loader/Loader";

const SeoList = React.lazy(() => import("./list"));
const SeoCreate = React.lazy(() => import("./create"));
const SeoUpdate = React.lazy(() => import("./update"));

export const characteristicSeo = [
  {
    path: ROUTES.seo.list,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <SeoList />
      </Suspense>
    )
  },
  {
    path: ROUTES.seo.create,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <SeoCreate />
      </Suspense>
    )
  },
  {
    path: ROUTES.seo.update,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <SeoUpdate />
      </Suspense>
    )
  }
];
