import React, { Suspense } from "react";
import Loader from "../../Components/Loader/Loader";

const SelectionAssocList = React.lazy(() =>
  import(
    "Components/SelectionsAssociation/SelectionAssocList/SelectionAssocList"
  )
);
const SelectionAssocUpdate = React.lazy(() =>
  import(
    "Components/SelectionsAssociation/SelectionAssocUpdate/SelectionAssocUpdate"
  )
);
const SelectionAssocCreate = React.lazy(() =>
  import(
    "Components/SelectionsAssociation/SelectionAssocCreate/SelectionAssocCreate"
  )
);

const urls = [
  {
    path: "/selectionsAssoc",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <SelectionAssocList />
      </Suspense>
    )
  },
  {
    path: "/selectionsAssoc/create/",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <SelectionAssocCreate />
      </Suspense>
    )
  },
  {
    path: "/selectionsAssoc/:id",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <SelectionAssocUpdate />
      </Suspense>
    )
  }
];

export default urls;
