import React, { Suspense } from "react";
import Loader from "../../../Components/Loader/Loader";

const SendNotification = React.lazy(() =>
  import("Components/MobileApp/SendNotification/SendNotification")
);

export const URL_NAME = "app/sendNotification";

const urls = [
  {
    path: `/${URL_NAME}`,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <SendNotification />
      </Suspense>
    )
  }
];

export default urls;
