import React, { Suspense } from "react";
import { ROUTES } from "../../../app/routes";
import Loader from "../../../Components/Loader/Loader";

const List = React.lazy(() =>
  import(
    "Components/Characteristics/CharacteristicValue/CharacteristicList/CharacteristicList"
  )
);

const Create = React.lazy(() =>
  import(
    "Components/Characteristics/CharacteristicValue/CharacteristicCreate/CharacteristicCreate"
  )
);

const Update = React.lazy(() =>
  import(
    "Components/Characteristics/CharacteristicValue/CharacteristicUpdate/CharacteristicUpdate"
  )
);

const urls = [
  {
    path: ROUTES.characteristicValueList,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <List />
      </Suspense>
    )
  },
  {
    path: ROUTES.characteristicValueCreate,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <Create />
      </Suspense>
    )
  },
  {
    path: ROUTES.characteristicValueUpdate,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <Update />
      </Suspense>
    )
  }
];

export default urls;
