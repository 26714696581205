import React, { Suspense } from "react";
import Loader from "../../../Components/Loader/Loader";
import { ROUTES } from "../../../app/routes";
const List = React.lazy(() =>
  import("Components/Locations/District/DistrictList/DistrictList")
);
const Create = React.lazy(() =>
  import("Components/Locations/District/DistrictCreate/DistrictCreate")
);
const Update = React.lazy(() =>
  import("Components/Locations/District/DistrictUpdate/DistrictUpdate")
);

const urls = [
  {
    path: ROUTES.districtList,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <List />
      </Suspense>
    )
  },
  {
    path: ROUTES.districtCreate,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <Create />
      </Suspense>
    )
  },
  {
    path: ROUTES.districtUpdate,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <Update />
      </Suspense>
    )
  }
];

export default urls;
