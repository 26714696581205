import React, { Suspense } from "react";
import Loader from "../../Components/Loader/Loader";
import { ROUTES } from "../../app/routes";
const List = React.lazy(() =>
  import("Components/Producer/ProducerList/ProducerList")
);
const Create = React.lazy(() =>
  import("Components/Producer/ProducerCreate/ProducerCreate")
);
const Update = React.lazy(() =>
  import("Components/Producer/ProducerUpdate/ProducerUpdate")
);

const urls = [
  {
    path: ROUTES.producerList,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <List />
      </Suspense>
    )
  },
  {
    path: ROUTES.producerCreate,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <Create />
      </Suspense>
    )
  },
  {
    path: ROUTES.producerUpdate,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <Update />
      </Suspense>
    )
  }
];

export default urls;
