import offers from "./offers";
import categories from "./categories";
import notifications from "./notifications";
import sendNotification from "./sendNotification";
import notificationsConfig from "./notificationsConfig";
import versions from "./versions";
const urls = [
  ...offers,
  ...categories,
  ...notifications,
  ...sendNotification,
  ...notificationsConfig,
  ...versions
];

export default urls;
