import React, { Suspense } from "react";
import Loader from "../../../Components/Loader/Loader";
const NotificationsList = React.lazy(() =>
  import(
    "Components/MobileApp/Notifications/NotificationsList/NotificationsList"
  )
);
const NotificationsCreate = React.lazy(() =>
  import(
    "Components/MobileApp/Notifications/NotificationsCreate/NotificationsCreate"
  )
);
const NotificationsUpdate = React.lazy(() =>
  import(
    "Components/MobileApp/Notifications/NotificationsUpdate/NotificationsUpdate"
  )
);

export const URL_NAME = "app/notifications";

const urls = [
  {
    path: `/${URL_NAME}`,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <NotificationsList />
      </Suspense>
    )
  },
  {
    path: `/${URL_NAME}/create`,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <NotificationsCreate />
      </Suspense>
    )
  },
  {
    path: `/${URL_NAME}/:id/`,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <NotificationsUpdate />
      </Suspense>
    )
  }
];

export default urls;
