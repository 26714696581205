import React, { createContext, useState, useEffect, useCallback } from "react";
import jwt from 'jsonwebtoken';
import { authService } from "../../Services/Api.auth";
import {localStorageService} from "../../Services/LocalStore";
import {TOKEN_NAME_LOCAL_STORE} from "../../Services/constants";

export const UserContext = createContext();

const UserContextProvider = ({ value, children }) => (
  <UserContext.Provider value={value}>{children}</UserContext.Provider>
);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [menu, setMenu] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const login = async (email, password) => {
    setIsLoading(true);
    setError(null);
    try {
      await authService.login(email, password);
      await checkLogin();
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await authService.logOut();
      setUser(null);
      setMenu([]);
      window.location.href = window.location.origin + "/login";
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const checkLogin = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const token = localStorageService.get(TOKEN_NAME_LOCAL_STORE);
      const decodedToken = jwt.decode(token);

      if (!decodedToken) throw new Error("NOT USER DATA");
      setUser(decodedToken);

      const menuData = await authService.getUserMenu();
      setMenu(menuData.data);
    } catch (err) {
      setError(err.message || "NOT USER DATA");
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    checkLogin();
  }, [checkLogin]);

  return (
    <UserContextProvider
      value={{
        user,
        menu,
        isLoading,
        error,
        login,
        logout,
        checkLogin,
      }}
    >
      {children}
    </UserContextProvider>
  );
};

export default UserProvider;