import React, { Suspense } from "react";
import Loader from "../../../Components/Loader/Loader";
const NotificationConfigsList = React.lazy(() =>
  import(
    "Components/MobileApp/NotificationConfigs/NotificationConfigsList/NotificationConfigsList"
  )
);
const NotificationConfigsCreate = React.lazy(() =>
  import(
    "Components/MobileApp/NotificationConfigs/NotificationConfigsCreate/NotificationConfigsCreate"
  )
);
const NotificationConfigsUpdate = React.lazy(() =>
  import(
    "Components/MobileApp/NotificationConfigs/NotificationConfigsUpdate/NotificationConfigsUpdate"
  )
);

export const URL_NAME = "app/notificationsConfig";

const urls = [
  {
    path: `/${URL_NAME}`,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <NotificationConfigsList />
      </Suspense>
    )
  },
  {
    path: `/${URL_NAME}/create`,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <NotificationConfigsCreate />
      </Suspense>
    )
  },
  {
    path: `/${URL_NAME}/:id/`,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <NotificationConfigsUpdate />
      </Suspense>
    )
  }
];

export default urls;
