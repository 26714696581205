import React, { Suspense } from "react";
import { ROUTES } from "app/routes";
import Loader from "Components/Loader/Loader";

const ListPage = React.lazy(() => import("./list"));
const ShowPage = React.lazy(() => import("./show"));
const CreatePage = React.lazy(() => import("./create"));
const UpdatePage = React.lazy(() => import("./update"));

export const products = [
  {
    path: ROUTES.products.list,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <ListPage />
      </Suspense>
    )
  },
  {
    path: ROUTES.products.create,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <CreatePage />
      </Suspense>
    )
  },
  {
    path: ROUTES.products.show,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <ShowPage />
      </Suspense>
    )
  },
  {
    path: ROUTES.products.update,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <UpdatePage />
      </Suspense>
    )
  }
];
