export const ROUTES = {
  cityList: "/cities",
  cityUpdate: "/cities/:id",
  cityCreate: "/cities/create",
  districtList: "/districts",
  districtUpdate: "/districts/:id",
  districtCreate: "/districts/create",
  marketsList: "/market",
  marketsUpdate: "/market/:id",
  marketsCreate: "/market/create",
  producerList: "/producer",
  producerUpdate: "/producer/:id",
  producerCreate: "/producer/create",
  characteristicKeyList: "/characteristic_key",
  characteristicKeyCreate: "/characteristic_key/create",
  characteristicKeyUpdate: "/characteristic_key/:id",
  characteristicValueList: "/characteristic_value",
  characteristicValueCreate: "/characteristic_value/create",
  characteristicValueUpdate: "/characteristic_value/:id",
  category: {
    list: "/category",
    create: "/category/create",
    update: "/category/:id"
  },
  capacityNames: {
    list: "/related-names",
    create: "/related-names/create",
    update: "/related-names/:id"
  },
  capacityProducts: {
    list: "/related-products",
    create: "/related-products/create",
    update: "/related-products/:id"
  },
  products: {
    list: "/product",
    show: "/product/:id",
    create: "/product/create",
    update: "/product/update/:id"
  },
  seo: {
    list: "/seo",
    create: "/seo/create",
    update: "/seo/update/:id"
  },
  schedule: {
    list: "/schedule",
    update: "/schedule/:id"
  },
  upperBanners: {
    list: "/upper-banners",
    create: "/upper-banners/create",
    update: "/upper-banners/:id"
  },
  contactMethods: {
    list: "/contact-methods",
    create: "/contact-methods/create",
    update: "/contact-methods/:id"
  },
  cartSettings: {
    update: "/cart-settings"
  }
};
