import React, { Suspense } from "react";
import { ROUTES } from "app/routes";
import Loader from "Components/Loader/Loader";

const ListPage = React.lazy(() => import("./list"));
const UpdatePage = React.lazy(() => import("./update"));

export const schedule = [
  {
    path: ROUTES.schedule.list,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <ListPage />
      </Suspense>
    )
  },
  {
    path: ROUTES.schedule.update,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <UpdatePage />
      </Suspense>
    )
  }
];
