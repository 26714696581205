import React, { createContext, useState } from "react";
import Notification from "../../Components/Notification/Notification";
import ModalLoader from "../../Components/Loader/ModalLoader";

export const NotificationContext = createContext({});

const NotificationContextProvider = ({ value, children }) => (
  <NotificationContext.Provider value={value}>
    {children}
  </NotificationContext.Provider>
);

function NotificationProvider(props) {
  const { children } = props;

  const [openNotification, setOpenNotification] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const startFetching = () => {
    setLoading(true);
    setError(null);
  };

  const endOfFetching = () => {
    setLoading(false);
    setOpenNotification(true);
  };

  const getErrorMessage = error => {
    console.error(error);
    if (!error) return null;
    const message = error.response?.data?.message;
    if (Array.isArray(message)) return message.join(", ");
    if (typeof message === "string") return message;
    return `Something went wrong`;
  };

  const value = {
    startFetching,
    endOfFetching,
    setError
  };

  return (
    <NotificationContextProvider value={value}>
      {children}
      <Notification
        open={openNotification}
        setOpen={setOpenNotification}
        errorMessage={getErrorMessage(error)}
      />
      <ModalLoader open={isLoading} />
    </NotificationContextProvider>
  );
}

export default NotificationProvider;
