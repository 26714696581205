import React, { Suspense } from "react";
import Loader from "../../../Components/Loader/Loader";
import { ROUTES } from "../../../app/routes";
const List = React.lazy(() =>
  import("Components/Locations/City/CitiesList/CityList")
);
const Create = React.lazy(() =>
  import("Components/Locations/City/CityCreate/CityCreate")
);
const Update = React.lazy(() =>
  import("Components/Locations/City/CityUpdate/CityUpdate")
);

const urls = [
  {
    path: ROUTES.cityList,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <List />
      </Suspense>
    )
  },
  {
    path: ROUTES.cityCreate,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <Create />
      </Suspense>
    )
  },
  {
    path: ROUTES.cityUpdate,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <Update />
      </Suspense>
    )
  }
];

export default urls;
