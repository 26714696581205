import React, { Suspense } from "react";
import { ROUTES } from "app/routes";
import Loader from "Components/Loader/Loader";

const ListPage = React.lazy(() => import("./list"));
const CreatePage = React.lazy(() => import("./create"));
const UpdatePage = React.lazy(() => import("./update"));

export const capacityProducts = [
  {
    path: ROUTES.capacityProducts.list,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <ListPage />
      </Suspense>
    )
  },
  {
    path: ROUTES.capacityProducts.create,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <CreatePage />
      </Suspense>
    )
  },
  {
    path: ROUTES.capacityProducts.update,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <UpdatePage />
      </Suspense>
    )
  }
];
