import axios from "axios";
import {
  MEMBERSHIP_URL,
  TOKEN_NAME_LOCAL_STORE,
  REFRESH_TOKEN_NAME_LOCAL_STORE
} from "./constants";
import { localStorageService } from "./LocalStore";

const getAuthToken = () => localStorageService.get(TOKEN_NAME_LOCAL_STORE);
const setAuthToken = token =>
  localStorageService.set(TOKEN_NAME_LOCAL_STORE, token);
const removeAuthToken = () =>
  localStorageService.remove(TOKEN_NAME_LOCAL_STORE);
const getRefreshToken = () =>
  localStorageService.get(REFRESH_TOKEN_NAME_LOCAL_STORE);
const setRefreshToken = token =>
  localStorageService.set(REFRESH_TOKEN_NAME_LOCAL_STORE, token);
const removeRefreshToken = () =>
  localStorageService.remove(REFRESH_TOKEN_NAME_LOCAL_STORE);

axios.interceptors.request.use(config => {
  const token = getAuthToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    removeAuthToken();
  }
  return config;
});

let prevErrorUrl = "";

async function updateAuthToken() {
  try {
    const { data } = await axios.post(`${MEMBERSHIP_URL}admin/auth/refresh`, {
      accessToken: getAuthToken(),
      refreshToken: getRefreshToken()
    });
    setAuthToken(data.data.accessToken);
    setRefreshToken(data.data.refreshToken);
    return true;
  } catch (err) {
    removeAuthToken();
    removeRefreshToken();
    throw err;
  }
}

axios.interceptors.response.use(
  response => response,
  async error => {
    if (
      error?.response?.status === 401 &&
      !prevErrorUrl.includes("/api/v2/admin/auth/refresh")
    ) {
      prevErrorUrl = error?.response.request.responseURL;
      setTimeout(() => {
        prevErrorUrl = "";
      }, 5000);

      try {
        await updateAuthToken();
        const token = getAuthToken();
        error.config.headers.Authorization = `Bearer ${token}`;
        return axios(error.config);
      } catch (e) {
        return Promise.reject(e);
      }
    }
    return Promise.reject(error);
  }
);

class AuthService {
  async login(email, password, remember = false) {
    try {
      const { data } = await axios.post(`${MEMBERSHIP_URL}admin/auth/signin`, {
        email,
        password
      });

      setAuthToken(data.data.accessToken);
      setRefreshToken(data.data.refreshToken);
      return true;
    } catch (err) {
      removeAuthToken();
      removeRefreshToken();
      throw err.response.data.message;
    }
  }

  async logOut() {
    await axios.post(`${MEMBERSHIP_URL}admin/auth/logout`);
    removeAuthToken();
    removeRefreshToken();
  }

  async getUserMenu() {
    const { data } = await axios.get(`${MEMBERSHIP_URL}admin/tools/user-menu`);
    return data;
  }
}

const authService = new AuthService();

export { axios, authService };
