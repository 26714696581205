import React, { Suspense } from "react";
import Loader from "../../Components/Loader/Loader";
const Search = React.lazy(() => import("Components/Search/Search"));

const urls = [
  {
    path: "/product-search",
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <Search />
      </Suspense>
    )
  }
];

export default urls;
