import React, { Suspense } from "react";
import Loader from "../../../Components/Loader/Loader";
import { ROUTES } from "../../../app/routes";
const List = React.lazy(() =>
  import("Components/Locations/Market/MarketList/MarketList")
);
const Create = React.lazy(() =>
  import("Components/Locations/Market/MarketCreate/MarketCreate")
);
const Update = React.lazy(() =>
  import("Components/Locations/Market/MarketUpdate/MarketUpdate")
);

const urls = [
  {
    path: ROUTES.marketsList,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <List />
      </Suspense>
    )
  },
  {
    path: ROUTES.marketsCreate,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <Create />
      </Suspense>
    )
  },
  {
    path: ROUTES.marketsUpdate,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <Update />
      </Suspense>
    )
  }
];

export default urls;
