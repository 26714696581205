import React, { Suspense } from "react";
import Loader from "../../../Components/Loader/Loader";
const List = React.lazy(() =>
  import("Components/MobileApp/Versions/VersionList/VersionList")
);
const Create = React.lazy(() =>
  import("Components/MobileApp/Versions/VersionCreate/VersionCreate")
);
const Update = React.lazy(() =>
  import("Components/MobileApp/Versions/VersionUpdate/VersionUpdate")
);

export const URL_NAME = "app/versions";

const urls = [
  {
    path: `/${URL_NAME}`,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <List />
      </Suspense>
    )
  },
  {
    path: `/${URL_NAME}/create`,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <Create />
      </Suspense>
    )
  },
  {
    path: `/${URL_NAME}/:id/`,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <Update />
      </Suspense>
    )
  }
];

export default urls;
