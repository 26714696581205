import React, { Suspense } from "react";
import { ROUTES } from "app/routes";
import Loader from "../../Components/Loader/Loader";

const ListCategoryPage = React.lazy(() => import("./list"));
const CreateCategoryPage = React.lazy(() => import("./create"));
const UpdateCategoryPage = React.lazy(() => import("./update"));

export const categories = [
  {
    path: ROUTES.category.list,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <ListCategoryPage />
      </Suspense>
    )
  },
  {
    path: ROUTES.category.create,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <CreateCategoryPage />
      </Suspense>
    )
  },
  {
    path: ROUTES.category.update,
    exact: true,
    component: (
      <Suspense fallback={<Loader />}>
        <UpdateCategoryPage />
      </Suspense>
    )
  }
];
